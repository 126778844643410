import BaseApp from 'next/app';
import { Router } from 'next/router';
import React from 'react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/zoom/zoom.min.css';
import 'swiper/swiper.scss';

import { checkMarketingCookies } from '../helpers/cookies/check-marketing-cookies';

import '../styles/index.scss';
import '../styles/navigation.scss';
import '../styles/pagination.scss';

const queryClient = new QueryClient();

const tagManagerArgs = {
  gtmId: 'GTM-KM26HCX',
};

const pixelArgs = {
  PIXEL_ID: '2418633318268893',
};

function trackPage(path) {
  TagManager.initialize(tagManagerArgs);

  TagManager.dataLayer({
    dataLayer: {
      event: 'Pageview',
      pagePath: path,
    },
  });
}

function initFacebookPixel() {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      const options = {
        autoConfig: true,
        debug: false,
      };

      ReactPixel.init(pixelArgs.PIXEL_ID, null, options);
      ReactPixel.pageView();

      Router.events.on('routeChangeComplete', () => {
        ReactPixel.pageView();
      });
    });
}

class App extends BaseApp {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    function activateTagManager() {
      if (checkMarketingCookies()) {
        TagManager.initialize(tagManagerArgs);
        Router.events.on('routeChangeComplete', trackPage);

        initFacebookPixel();
      }
    }

    let pollAnimationFrame;

    function pollOneTrust() {
      if (
        typeof OneTrust !== 'undefined' &&
        typeof OnetrustActiveGroups !== 'undefined'
      ) {
        activateTagManager();
        OneTrust?.OnConsentChanged(activateTagManager);
        cancelAnimationFrame(pollAnimationFrame);
      } else {
        pollAnimationFrame = requestAnimationFrame(pollOneTrust);
      }
    }

    pollOneTrust();
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', trackPage);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    );
  }
}

export default App;
